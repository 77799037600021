import { useEffect, useRef, useState } from 'react';
import {
    Button, Col, Input, Modal, Row, Table, notification,
} from 'antd';
import { BiTrash } from 'react-icons/bi';
import moment from 'moment';

import { deleteTransaction, getTransactionConcepts, getTransactions } from '../../../api/payments';
import { AddTransactionModal } from './AddTransactionModal';
import { OCTOPUS_BASE_URL } from '../../../api';
import { IInvoiceTransaction } from '../../Invoices/context/invoices.interface';

const Concept = ({ transaction }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [concept, setConcept] = useState<any>();
    useEffect(() => {
        setLoading(true);
        getTransactionConcepts(transaction._id)
            .then(({ data }: any) => {
                setConcept(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, [transaction]);

    const columns = [
        {
            title: 'Concept',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => amount.toFixed(2),
        },
    ];
    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={concept?.concepts}
            rowKey="_id"
            size="small"
            footer={() => `Total: ${(concept?.total || 0).toFixed(2)}`}
            pagination={false}
        />
    );
};

const PaymentTransactions = ({ payment, onClose }: any) => {
    const [loading, seLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [newTransaction, setNewTransaction] = useState<boolean>(false);
    const allTransactions = useRef<IInvoiceTransaction[]>([]);

    useEffect(() => {
        if (!payment) return;
        seLoading(true);
        getTransactions(payment._id)
            .then(({ data }: any) => {
                allTransactions.current = data.data;
                setTransactions(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => seLoading(false));
    }, [payment]);

    const isOpen = payment;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Business',
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Booking',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm'),
            width: 130,
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
        },
        {
            title: 'Order Amount',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 100,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => amount.toFixed(2),
            width: 100,
        },
        {
            dataIndex: '_id',
            key: 'actions',
            render: (transactionId: any, transaction: IInvoiceTransaction) => (
                <Button
                    type="text"
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: `Do you want to delete this transaction? ${transaction.transactionId}`,
                            onOk: async () => {
                                try {
                                    const transaction = await deleteTransaction(transactionId);
                                    if (transaction) {
                                        setTransactions((transactions) => transactions.filter((t) => t._id !== transactionId));
                                        notification.success({
                                            message: 'Transaction deleted',
                                        });
                                    }
                                } catch (error) {
                                    notification.error({
                                        message: 'Error deleting transaction',
                                    });
                                }
                            },
                        });
                    }}
                >
                    <BiTrash />
                </Button>
            ),
            width: 60,
        },
    ];

    const handleNewTransaction = () => {
        setNewTransaction(true);
    };

    const handleCancelNewTransaction = (transaction: any) => {
        if (transaction) {
            setTransactions((transactions: any) => [...transactions, transaction]);
        }
        setNewTransaction(false);
    };

    const handleDownload = () => {
        const token = localStorage.getItem('token');
        const paymentId = payment?._id;
        const utc = (moment().utcOffset() / 60).toString();
        window.open(`${OCTOPUS_BASE_URL}/reports?CSVtype=invoicesReport&paymentId=${paymentId}&token=${token}&utc=${utc}`, '_blank');
    };

    const handleClose = () => {
        const amount = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
        const newPayment = {
            ...payment,
            amount,
        };
        onClose(newPayment);
    };

    const handleSearch = (value: string) => {
        if (!value) {
            return setTransactions(allTransactions.current);
        }
        const filtered = allTransactions.current.filter(
            (transaction) => transaction.bookingNumericId.toString().includes(value) || transaction.transactionId.toString().includes(value),
        );
        setTransactions(filtered);
    };

    return (
        <Modal
            title={`Transactions: ${payment?.invoiceId || ''}`}
            open={isOpen}
            width="100%"
            onOk={handleClose}
            onCancel={handleClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <AddTransactionModal
                visible={newTransaction}
                dispersion={payment}
                onCancel={(transaction: any) => handleCancelNewTransaction(transaction)}
            />
            <Row justify="end" gutter={[10, 20]}>
                <Col>
                    <Input.Search placeholder="Booking, Transaction" onSearch={handleSearch} />
                </Col>
                <Col>
                    <Button onClick={handleDownload}>
                        Download
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleNewTransaction}>
                        Add transaction
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={transactions}
                        bordered
                        scroll={{ x: 1000 }}
                        rowKey="_id"
                        size="small"
                        expandable={{
                            rowExpandable: (record) => !!record.bookingNumericId,
                            expandedRowRender: (record) => <Concept transaction={record} />,
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default PaymentTransactions;
