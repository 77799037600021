import {
    Card, Row, Space, Spin, Timeline, Typography, message,
    notification,
} from 'antd';
import moment from 'moment';
import { BsBell } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import GuideEventCard from './GuideEventCard';
import { IGuideEvent } from '../../../interfaces/express-delivery.interface';
import { getEventsByGuideId } from '../../../api/guides';

interface EventsProps {
    guideId: string;
}

const { Text } = Typography;

export const Events = ({ guideId }: EventsProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<IGuideEvent[]>([]);

    const fetchGuideEvents = async (id: string) => {
        setLoading(true);
        getEventsByGuideId(id)
            .then(({ data: { data } }: any) => {
                setEvents(data);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!guideId) return;
        fetchGuideEvents(guideId);
    }, [guideId]);

    const NoEventData = () => (
        <Row justify="center">
            <Text>
                No events data
            </Text>
        </Row>
    );

    return (
        <Card
            title={(
                <Space size={8} align="center">
                    <BsBell style={{ verticalAlign: 'middle' }} />
                    <Text>Events</Text>
                </Space>
            )}
        >
            {
                loading ? (
                    <Space size={8}>
                        <Spin size="small" />
                        <Text>
                            Loading events...
                        </Text>
                    </Space>
                ) : (
                    <Timeline mode="left">

                        {events.length > 0
                            ? events.map((item) => {
                                const time = moment(item.createdAt).local().format('HH:mm:ss:SSS');
                                const date = moment(item.createdAt).local().format('ddd DD MMM');

                                return (
                                    <Timeline.Item
                                        key={item._id}
                                        label={(
                                            <Space size={4}>
                                                <Text>
                                                    {date}
                                                </Text>
                                                <Text type="secondary">
                                                    at
                                                </Text>
                                                <Text>
                                                    {time}
                                                </Text>
                                            </Space>
                                        )}
                                    >
                                        <GuideEventCard eventData={item} />
                                    </Timeline.Item>
                                );
                            }) : <NoEventData />}

                    </Timeline>
                )
            }
        </Card>
    );
};
